@tailwind base;
@tailwind components;
@tailwind utilities;


.section {
    @apply max-w-6xl mx-auto;
}

.radial-gradient {
    background: radial-gradient(circle at bottom, rgba(97, 69, 143, 1) 0%, rgba(15, 23, 42, 1) 58%, rgba(15, 23, 42, 1) 100%);
}

.scrollArea-custom {
    @apply overflow-y-auto;
}

.scrollArea-custom::-webkit-scrollbar {
    @apply w-1;
}

.scrollArea-custom::-webkit-scrollbar-track {
    @apply bg-transparent
}

.scrollArea-custom::-webkit-scrollbar-thumb {
    @apply bg-gray-500;
}